import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';

import { Spacing, Color } from 'styles/constants';

import logo from './assets/logo.png';

import bsFlag from './assets/bs-flag.png';
import enFlag from './assets/en-flag.png';

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
  padding: ${Spacing.content.lg} 0;
`;

const StyledImg = styled.img`
  width: 150px;
  cursor: pointer;
`;

const MenuContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNavLink = styled(NavLink)`
  color: ${Color.light};
  text-decoration: none;
  padding: ${Spacing.content.sm} ${Spacing.content.lg};
  @media (max-width: 576px) {
    display: none;
  }
`;

const FlagImg = styled.img`
  margin: 0 0.5rem;
  width: 25px;
  cursor: pointer;
`;

const Header = ({ ...rest }) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const navigateHome = () => {
    navigate('/');
  };

  const handleChangeLanguage = isoCode => {
    i18n.changeLanguage(isoCode);
    localStorage.setItem('lang', isoCode);
  };

  return (
    <Wrapper {...rest}>
      <Container>
        <HeaderWrapper>
          <Row justify="between" align="center">
            <Col xs="content" onClick={navigateHome}>
              <StyledImg src={logo} alt="logo" />
            </Col>
            <Col xs="content">
              <MenuContent>
                <StyledNavLink to="/">{t('Header.Home')}</StyledNavLink>
                <FlagImg src={bsFlag} alt="bs-flag" onClick={() => handleChangeLanguage('bs')} />
                <FlagImg src={enFlag} alt="en-flag" onClick={() => handleChangeLanguage('en')} />
              </MenuContent>
              {/* <StyledNavLink to="/rivers">{t("Header.Rivers")}</StyledNavLink>
              <StyledNavLink to="/faq">{t("Header.FAQ")}</StyledNavLink> */}
            </Col>
          </Row>
        </HeaderWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;
