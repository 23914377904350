import React, { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
// import { Col, Container, Row } from 'react-grid-system';

import Header from 'components/Header';
import Layout from 'components/Layout';
import Loading from 'components/Loading';

const Biostica = lazy(() => import('pages/Rivers/Biostica'));
const Bregava = lazy(() => import('pages/Rivers/Bregava'));
const Cehotina = lazy(() => import('pages/Rivers/Cehotina'));
const Dabar = lazy(() => import('pages/Rivers/Dabar'));
const Krivaja = lazy(() => import('pages/Rivers/Krivaja'));
const Neretva = lazy(() => import('pages/Rivers/Neretva'));
const Praca = lazy(() => import('pages/Rivers/Praca'));
const Sana = lazy(() => import('pages/Rivers/Sana'));
const Sanica = lazy(() => import('pages/Rivers/Sanica'));
const Vrbas = lazy(() => import('pages/Rivers/Vrbas'));
const Una = lazy(() => import('pages/Rivers/Una'));
const Ugar = lazy(() => import('pages/Rivers/Ugar'));
const Pliva = lazy(() => import('pages/Rivers/Pliva'));
const Fojnica = lazy(() => import('pages/Rivers/Fojnica'));
const Drina = lazy(() => import('pages/Rivers/Drina'));
const Tara = lazy(() => import('pages/Rivers/Tara'));

const StyledHeader = styled(Header)`
  background: linear-gradient(216.04deg, #27c0ef 13.84%, #00a8db 83.53%);
`;

const riverComponents = {
  biostica: <Biostica />,
  bregava: <Bregava />,
  cehotina: <Cehotina />,
  dabar: <Dabar />,
  krivaja: <Krivaja />,
  neretva: <Neretva />,
  praca: <Praca />,
  sana: <Sana />,
  sanica: <Sanica />,
  vrbas: <Vrbas />,
  una: <Una />,
  ugar: <Ugar />,
  pliva: <Pliva />,
  fojnica: <Fojnica />,
  drina: <Drina />,
  tara: <Tara />
};

const renderComponent = riverName => {
  return riverComponents[riverName];
};

const RiverDetails = () => {
  const { riverUniqueName } = useParams();

  return (
    <Layout>
      <StyledHeader />

      <Suspense fallback={<Loading />}>{renderComponent(riverUniqueName)}</Suspense>
    </Layout>
  );
};

export default RiverDetails;
