import React from 'react';
import styled from 'styled-components';

import Layout from 'components/Layout';
import Header from 'components/Header';

import HeroSection from './HeroSection';
import SponsorSection from './SponsorSection';
import RiverList from './RiverList';

const Cover = styled.div`
  width: 100%;
  min-height: 80vh;
  background: linear-gradient(216.04deg, #27c0ef 13.84%, #00a8db 83.53%);
  position: relative;
`;

const Landing = () => {
  return (
    <Layout>
      <Cover>
        <Header />
        <HeroSection />
      </Cover>

      <SponsorSection />

      <RiverList />
    </Layout>
  );
};

export default Landing;
