import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { styled } from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(216.04deg, #27c0ef 13.84%, #00a8db 83.53%);
`;

const StyledSpan = styled.span`
  color: #fff;
  font-size: 1.2rem;
`;

const NotFound = () => {
  return (
    <Wrapper>
      <Container>
        <Row justify="center" align="center">
          <Col xs="content">
            <StyledSpan>404 - Not found</StyledSpan>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default NotFound;
