import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Col, Container, Row } from 'react-grid-system';

import { bosnian } from 'common/languages';

import USAIDLogoBH from './assets/usaid-logo-bh.png';
import USAIDLogoEN from './assets/usaid-logo-en.png';

const Wrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const StyledImg = styled.img`
  width: 100%;
  cursor: pointer;
`;

const StyledH3 = styled.h3`
  color: #034d82;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`;

const SponsorSection = () => {
  const { t } = useTranslation();

  const USAIDLogo = localStorage.getItem('lang') === bosnian ? USAIDLogoBH : USAIDLogoEN;

  return (
    <Container>
      <Row justify="center">
        <Col xs={12} sm={5} md={5}>
          <Wrapper>
            <StyledH3>{t('SponsorSection.Heading')}</StyledH3>
            <a href="https://turizambih.ba/" target="_blank" rel="noreferrer">
              <StyledImg src={USAIDLogo} />
            </a>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default SponsorSection;
