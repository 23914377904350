import React from 'react';
import styled from 'styled-components';

import { Col, Container, Row } from 'react-grid-system';

import { ReactComponent as HeartIcon } from './assets/heart-icon.svg';
import { ReactComponent as InstagramIcon } from './assets/instagram-icon.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook-icon.svg';

import logoText from './assets/logo-text.png';

const FooterWrapper = styled.div`
  margin-top: 8rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  background: #081315;
`;

const IconWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  background: #005490;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  padding: 0.5rem;
  width: min-content;
  margin-bottom: 0.5rem;
`;

const FooterParagraph = styled.p`
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
`;

const FlexCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const StyledImg = styled.img`
  width: 80%;
`;

const StyledParagraph = styled.p`
  color: #d3d6d8;
  font-size: 0.8rem;
  line-height: 1.4rem;
`;

const SocialIconLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background: #1d282a;
  border-radius: 50px;
  width: min-content;
  margin-right: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Row>
          <Col xs={6} sm={3} md={3}>
            <StyledImg src={logoText} alt="logo" />
            <StyledParagraph>In water we live, in water we trust. Wild Water.</StyledParagraph>

            <Wrapper>
              <SocialIconLink href="https://www.instagram.com/bh.white.water/" target="_blank">
                <InstagramIcon />
              </SocialIconLink>

              <SocialIconLink href="https://www.facebook.com/bh.white.water" target="_blank">
                <FacebookIcon />
              </SocialIconLink>
            </Wrapper>
          </Col>
          <Col>
            <div>Product</div>
          </Col>
        </Row>
        <Row justify="center">
          <FlexCol xs={1}>
            <IconWrapper>
              <HeartIcon />
            </IconWrapper>
          </FlexCol>
        </Row>
        <Row justify="center">
          <Col>
            <FooterParagraph>Copyright © 2023. Crafted with love.</FooterParagraph>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
