import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import NotFound from 'components/NotFound';
import Loading from 'components/Loading';

import RiverDetails from 'pages/RiverDetails';
import Landing from 'pages/Landing';

import './i18n';
// import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/rivers/:riverUniqueName" element={<RiverDetails />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
