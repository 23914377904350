import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'components/Footer';

const Layout = props => {
  const { children } = props;

  return (
    <>
      {children && children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
